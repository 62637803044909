import React, { memo } from 'react';
import { SEO } from '@components';

import './solutions-and-systems.scss';
import {
  backendTechnologies,
  frontendTechnologies,
  mobileTechnologies,
  overviewItems,
  oodenEAD,
} from '@content';
import { LandingTechnology, LandingOverview, LandingWork } from '@containers';

const SolutionsAndSystems = () => (
  <div>
    <SEO title="Solutions and systems" />
    <div className="on-wwd-main">
      <div className="on-wwd-container">
        <div className="on-wwd-text">
          <h1>Solutions and systems</h1>
          <p>
            Every business works differently but why use the same shelf software solutions that miss
            features and offer useless feature you don't need ?
          </p>
          <p>
            Wouldn't it be great if you could use a single software solution that fulfills your
            needs exactly with great user experience, high stability and data protection ?
          </p>
        </div>
      </div>
      <div className="on-wwd-text on-wwd-item">
        <h1>Why ooden ?</h1>
        <LandingOverview text="" items={oodenEAD} />
      </div>
      <LandingTechnology text="Backend" items={backendTechnologies} />
      <LandingTechnology text="Frontend" items={frontendTechnologies} />
      <LandingTechnology text="Mobile" items={mobileTechnologies} />
      <div className="on-wwd-text">
        <p>
          Investing in custom software development allows you to increase productivity and gain a
          competitive edge over your competitors that are still using shelf software solutions. Why
          not stand out from the crowd?
        </p>{' '}
        <LandingWork text="All you need is the right partner. We can be that partner." />
        <h1></h1>
        <LandingOverview text="How we can generally help" items={overviewItems} />
      </div>

      <div className="on-wwd-padding" />
    </div>
  </div>
);

export default memo(SolutionsAndSystems);
